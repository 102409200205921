export const DIRECT_DEBIT_COLLECTION_STATUS = {
  ON_HOLD: 'on_hold',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  REFUNDED: 'refunded',
};

export const DIRECT_DEBIT_COLLECTION_TYPES = {
  CORE: 'core',
};

export const DIRECT_DEBIT_COLLECTION_STATUS_LEVELS = {
  [DIRECT_DEBIT_COLLECTION_STATUS.CANCELED]: 'error',
  [DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED]: 'error',
  [DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED]: 'validated',
  [DIRECT_DEBIT_COLLECTION_STATUS.DECLINED]: 'error',
  [DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD]: 'validated',
};

export const DIRECT_DEBIT_COLLECTION_STATUS_REASON = {
  AMOUNT_LIMIT_REACHED: 'amount_limit_reached',
  ELIGIBILITY_LOST: 'eligibility_lost',
};

// read more about concept of r_transaction (return, refund, reject) in the dive-in
// https://gitlab.qonto.co/front/divein/-/merge_requests/496
export const R_TRANSACTION_TYPES = {
  REFUND: 'refund',
  RETURN: 'return',
};

export const LOCALIZED_TYPEFORM_URLS = {
  en: 'https://qonto.typeform.com/to/wTL8I7ZI',
  fr: 'https://qonto.typeform.com/to/JxCthUX4',
  it: 'https://qonto.typeform.com/to/MTcKfZbE',
  es: 'https://qonto.typeform.com/to/GvYaXCED',
  de: 'https://qonto.typeform.com/to/wj0txIDH',
};

export const DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS = {
  ELIGIBLE: 'eligible',
  NOT_ELIGIBLE: 'not_eligible',
  ACTIVATED: 'activated',
  ACTIVATED_NOT_ELIGIBLE: 'activated_not_eligible',
  PENDING_CREDITOR_IDENTIFIER_VERIFICATION: 'pending_cid_verification',
  PENDING_CREDITOR_IDENTIFIER_CREATION: 'pending_cid_creation',
};

export const SDD_MANDATE_STATUS = {
  PENDING: 'pending_signature',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  DECLINED: 'declined',
};

export const SDD_INDIVIDUAL_COLLECTION_LIMIT = 150000;
