const LOCALE_ARRAY = [
  { name: 'English', code: 'en' },
  { name: 'Français', code: 'fr' },
  { name: 'Italiano', code: 'it' },
  { name: 'Deutsch', code: 'de' },
  { name: 'Español', code: 'es' },
  { name: 'Português', code: 'pt' },
];

export const SUPPORTED_LOCALES = ['en', 'fr', 'it', 'de', 'es'];

export default LOCALE_ARRAY;
