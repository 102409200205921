import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import {
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS,
} from 'qonto/constants/direct-debit-subscriptions';
import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const name = 'incoming-direct-debits';
const featureName = 'directDebitsCollection';

const EMPTY_RESULTS_ILLUSTRATION_SRC =
  '/illustrations/direct-debit-collections/not-available-yet.svg';

const INFORM_LOTTIE_SRC = '/lotties/direct-debit-collection/incoming-direct-debit.json';
const INFORM_LOTTIE_SRC_TASKS = '/lotties/empty-state/no-tasks.json';
const EMPTY_LOTTIE_SEARCH = '/lotties/empty-state/search-no-result.json';

export const TAB_NAMES = {
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  TASKS: 'tasks',
};

function getA3Translation(key, intl) {
  let translations = {
    'activated-with-ebics': {
      title: intl.t('sdd-collections.empty-state.a3.activated.with-ebics.title'),
      subtitle: intl.t('sdd-collections.empty-state.a3.activated.with-ebics.subtitle'),
      ctaText: intl.t('sdd-collections.empty-state.a3.activated.with-ebics.cta'),
    },
    'activated-without-ebics': {
      title: intl.t('sdd-collections.empty-state.a3.activated.without-ebics.title'),
      subtitle: intl.t('sdd-collections.empty-state.a3.activated.without-ebics.subtitle'),
      ctaText: intl.t('sdd-collections.empty-state.a3.activated.without-ebics.cta'),
    },
    eligible: {
      title: intl.t('sdd-collections.empty-state.a3.eligible.title'),
      subtitle: intl.t('sdd-collections.empty-state.a3.eligible.subtitle'),
      ctaText: intl.t('sdd-collections.empty-state.a3.eligible.cta'),
    },
  };
  return translations[key];
}

export const getEmptyStateConfig = (
  intl,
  { ctaCallback, secondaryCtaCallback, isActivated, canViewEbicsCta }
) => {
  let dummyData = getDummyData(intl);

  let a3Translations = getA3Translation(
    isActivated
      ? canViewEbicsCta
        ? 'activated-with-ebics'
        : 'activated-without-ebics'
      : 'eligible',
    intl
  );

  return {
    name,
    featureName,
    permissionKey: 'directDebitCollections',

    variations: {
      ES_A2: [
        {
          output: {
            code: CODES.ES_A2,
            layout: LAYOUT.DISCOVER_PLAIN,
            title: intl.t('sdd-collections.empty-state.a2.not-eligible.title'),
            subtitle: intl.t('sdd-collections.empty-state.a2.not-eligible.subtitle'),
            ctaText: intl.t('sdd-collections.empty-state.a2.not-eligible.cta'),
            badgeType: BADGE_TYPE.NOT_AVAILABLE,
            badgeText: intl.t('empty-states.system.engage.a2.badge'),
            illuSrc: EMPTY_RESULTS_ILLUSTRATION_SRC,
            onClick: ctaCallback,
            tracking: getTrackingNameAndProperties({
              name,
              type: TYPES.ACTIVATE,
              customProps: { sdd_status: 'not-eligible' },
            }),
          },
        },
      ],
      ES_A3: [
        {
          output: {
            code: CODES.ES_A3,
            layout: LAYOUT.DISCOVER_PREVIEW,
            title: a3Translations.title,
            subtitle: a3Translations.subtitle,
            ctaText: a3Translations.ctaText,
            secondaryCtaText: canViewEbicsCta
              ? intl.t('sdd-collections.empty-state.a3.activated.with-ebics.tertiary-cta')
              : undefined,
            onSecondaryCtaClick: canViewEbicsCta ? secondaryCtaCallback : undefined,
            badgeType: BADGE_TYPE.ACTIVATE,
            badgeText: intl.t('empty-states.system.activate.a3.badge'),
            onClick: ctaCallback,
            dummyData,
            tracking: getTrackingNameAndProperties({
              name,
              type: TYPES.ACTIVATE,
              customProps: {
                sdd_status: isActivated ? 'eligible-activated' : 'eligible-not-activated',
              },
            }),
          },
        },
      ],
      ES_I1: [
        {
          code: CODES.ES_I1,
          layout: LAYOUT.INFORM,
          tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          inputs: {
            tab: TAB_NAMES.TASKS,
          },
          output: {
            code: CODES.ES_I1,
            layout: LAYOUT.INFORM,
            title: intl.t('sdd-collections.empty-state.i1.tasks.title'),
            subtitle: intl.t('sdd-collections.empty-state.i1.tasks.subtitle'),
            lottieSrc: INFORM_LOTTIE_SRC_TASKS,
            tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          },
        },
        {
          inputs: {
            tab: TAB_NAMES.COMPLETED,
          },
          output: {
            code: CODES.ES_I1,
            layout: LAYOUT.INFORM,
            title: intl.t('sdd-collections.empty-state.i1.completed.title'),
            subtitle: intl.t('sdd-collections.empty-state.i1.completed.subtitle'),
            lottieSrc: INFORM_LOTTIE_SRC,
            tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          },
        },
        {
          inputs: {
            tab: TAB_NAMES.PROCESSING,
          },
          output: {
            code: CODES.ES_I1,
            layout: LAYOUT.INFORM,
            title: intl.t('sdd-collections.empty-state.i1.processing.title'),
            subtitle: intl.t('sdd-collections.empty-state.i1.processing.subtitle'),
            lottieSrc: INFORM_LOTTIE_SRC,
            tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          },
        },
      ],
      ES_I2: [
        {
          output: {
            code: CODES.ES_I2,
            layout: LAYOUT.INFORM,
            lottieSrc: EMPTY_LOTTIE_SEARCH,
            tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
            title: intl.t('empty-states.system.inform.i2.title'),
            subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
          },
        },
      ],
    },
  };
};

export const getDummyData = intl => {
  return {
    rows: [
      // 1st Row
      {
        activityTag: ACTIVITY_TAGS.OTHER_INCOME,
        amount: {
          currency: 'EUR',
          value: intl.t('sdd-collections.empty-state.dummy-data.row-1.amount'),
        },
        debitorName: intl.t('sdd-collections.empty-state.dummy-data.row-1.debitor'),
        id: '1',
        nextCollectionDate: dayjs().subtract(19, 'day'),
        reference: intl.t('sdd-collections.empty-state.dummy-data.row-1.reference'),
        scheduleTypeLabel: intl.t('sdd-collections.recurrence.once'),
        statusDisplayInfo: {
          level:
            DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS[DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS],
          icon: DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS[
            DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS
          ],
          title: intl.t('sdd-collections.status.in-progress'),
        },
      },
      // 2nd Row
      {
        activityTag: ACTIVITY_TAGS.OTHER_INCOME,
        amount: {
          currency: 'EUR',
          value: intl.t('sdd-collections.empty-state.dummy-data.row-2.amount'),
        },
        debitorName: intl.t('sdd-collections.empty-state.dummy-data.row-2.debitor'),
        id: '2',
        nextCollectionDate: dayjs().subtract(17, 'day'),
        reference: intl.t('sdd-collections.empty-state.dummy-data.row-2.reference'),
        scheduleTypeLabel: intl.t('sdd-collections.recurrence.monthly'),
        statusDisplayInfo: {
          level:
            DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS[DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED],
          icon: DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS[DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED],
          title: intl.t('sdd-collections.status.scheduled'),
        },
      },
      // 3rd Row
      {
        activityTag: ACTIVITY_TAGS.OTHER_INCOME,
        amount: {
          currency: 'EUR',
          value: intl.t('sdd-collections.empty-state.dummy-data.row-3.amount'),
        },
        debitorName: intl.t('sdd-collections.empty-state.dummy-data.row-3.debitor'),
        id: '3',
        nextCollectionDate: dayjs().subtract(15, 'day'),
        reference: intl.t('sdd-collections.empty-state.dummy-data.row-3.reference'),
        scheduleTypeLabel: intl.t('sdd-collections.recurrence.yearly'),
        statusDisplayInfo: {
          level:
            DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS[DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED],
          icon: DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS[DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED],
          title: intl.t('sdd-collections.status.scheduled'),
        },
      },
    ],
  };
};
