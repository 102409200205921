import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import { getTrackingNameAndProperties, LAYOUT, TYPES } from 'qonto/constants/empty-states/system';
import { STATUS as STATUS_REQUESTS } from 'qonto/constants/requests';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { STATUS } from 'qonto/constants/transfers';
import { addDaysToToday, subDaysToToday } from 'qonto/utils/date';

import { REQUEST_TYPES } from '../requests';

export const TAB_NAMES = {
  PROCESSING: 'processing',
  COMPLETED: 'past',
  REQUESTS: 'requests',
};

const name = 'transfers';
const featureName = 'sepaTransfers';

const EMPTY_LOTTIE_TASKS = '/lotties/empty-state/no-tasks.json';
const EMPTY_LOTTIE_TRANSFERS = '/lotties/empty-state/no-transfers.json';

export const getEmptyStateConfig = intl => {
  return {
    name,
    featureName,
    isNewLogic: true,
    variations: {
      ES_A3: {
        code: 'ES_A3',
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              activateAbilities: [`canCreateExternalTransfers`],
            },
            output: {
              title: intl.t('transfers.empty-state.a3.create-transfer.title'),
              subtitle: intl.t('transfers.empty-state.a3.create-transfer.subtitle'),
              dummyData: getDummyData(intl, true),
            },
          },
          {
            inputs: {
              activateAbilities: [`canRequestTransfers`],
            },
            output: {
              title: intl.t('transfers.empty-state.a3.request-transfer.title'),
              subtitle: intl.t('transfers.empty-state.a3.request-transfer.subtitle'),
              dummyData: getDummyData(intl, false),
            },
          },
        ],
      },
      ES_I1: {
        code: 'ES_I1',
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              activateAbilities: ['hasConnectFeature'],
              customInputs: {
                tab: TAB_NAMES.REQUESTS,
                isSubAccountIsClosed: false,
              },
            },
            output: {
              title: intl.t('transfers.empty-state.i1.tasks.title'),
              subtitle: intl.t('transfers.empty-state.i1.tasks.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TASKS,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.REQUESTS,
                isSubAccountIsClosed: false,
              },
            },
            output: {
              title: intl.t('transfers.empty-state.i1.tasks.title'),
              subtitleWithLink: {
                key: 'transfers.empty-state.i1.tasks.third-party.subtitle',
                text: intl.t('transfers.empty-state.i1.tasks.third-party.subtitle', {
                  link: intl.t('transfers.empty-state.i1.tasks.third-party.link-text'),
                }),
                link: intl.t('transfers.empty-state.i1.tasks.third-party.link'),
              },
              lottieSrc: EMPTY_LOTTIE_TASKS,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.PROCESSING,
                isSubAccountIsClosed: false,
              },
            },
            output: {
              title: intl.t('transfers.empty-state.i1.processing.title'),
              subtitle: intl.t('transfers.empty-state.i1.processing.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TRANSFERS,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.COMPLETED,
                isSubAccountIsClosed: false,
              },
            },
            output: {
              title: intl.t('transfers.empty-state.i1.completed.title'),
              subtitle: intl.t('transfers.empty-state.i1.completed.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TRANSFERS,
            },
          },
          {
            inputs: {
              copyAbilities: ['canReadBankAccounts', 'hasMultiAccounts'],
              customInputs: {
                isSubAccountIsClosed: true,
              },
            },
            output: {
              title: intl.t('transfers.empty-state.i1.account-closed.title'),
              subtitle: intl.t('transfers.empty-state.i1.account-closed.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TRANSFERS,
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = (intl, isApprover) => {
  let createTransfers = {
    isApprover: true,
    items: [
      {
        id: '1',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-3'),
        },
        avatarInfo: {
          smallLogo: `/icon/category/transport-m.svg`,
          icon: 'status_scheduled',
        },
        operationType: SCHEDULE_OPERATION_TYPES.MONTHLY,
        isStanding: true,
        nextRecursionDate: addDaysToToday(15),
        instant: false,
        isDeclined: false,
        isCanceled: false,
        isCompleted: false,
        isProcessing: false,
        lastRecursionDate: subDaysToToday(15),
        status: STATUS.PENDING,
        localAmount: 2314,
        localAmountCurrency: 'EUR',
      },
      {
        id: '2',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-4'),
        },
        avatarInfo: {
          smallLogo: `/icon/category/restaurant_and_bar-m.svg`,
          icon: 'status_processing',
        },
        operationType: '',
        isStanding: true,
        nextRecursionDate: addDaysToToday(16),
        instant: false,
        isDeclined: false,
        isCanceled: false,
        isCompleted: false,
        isProcessing: true,
        lastRecursionDate: subDaysToToday(6),
        status: STATUS.PROCESSING,
        localAmount: 210,
        localAmountCurrency: 'EUR',
      },
      {
        id: '3',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-1'),
        },
        avatarInfo: {
          smallLogo: `/icon/category/online_service-m.svg`,
          icon: 'status_scheduled',
        },
        operationType: SCHEDULE_OPERATION_TYPES.WEEKLY,
        isStanding: true,
        nextRecursionDate: addDaysToToday(17),
        instant: false,
        isDeclined: false,
        isCanceled: false,
        isCompleted: false,
        isProcessing: false,
        lastRecursionDate: subDaysToToday(10),
        status: STATUS.PENDING,
        localAmount: 1108,
        localAmountCurrency: 'EUR',
      },
      {
        id: '4',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-2'),
        },
        avatarInfo: {
          smallLogo: `/icon/category/salary-m.svg`,
          icon: 'status_processing',
        },
        operationType: '',
        isStanding: true,
        nextRecursionDate: addDaysToToday(18),
        instant: false,
        isDeclined: false,
        isCanceled: false,
        isCompleted: false,
        isProcessing: true,
        lastRecursionDate: subDaysToToday(10),
        status: STATUS.PROCESSING,
        localAmount: 2314,
        localAmountCurrency: 'EUR',
      },
    ],
  };

  let requestTransfers = {
    isApprover: false,
    items: [
      {
        id: '1',
        requestType: REQUEST_TYPES.TRANSFER,
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-9'),
        },
        status: STATUS_REQUESTS.PENDING,
        note: intl.t('transfers.empty-state.a3.request-transfer.dummy-data.row-1.spending-reason'),
        once: true,
        displayedStatus: intl.t('request.status.pending'),
        amount: 115,
        amountCurrency: 'EUR',
      },
      {
        id: '2',
        requestType: REQUEST_TYPES.MULTI_TRANSFER,
        status: STATUS_REQUESTS.APPROVED,
        totalTransfersCount: 10,
        note: intl.t('transfers.empty-state.a3.request-transfer.dummy-data.row-2.spending-reason'),
        once: false,
        scheduledDate: addDaysToToday(5),
        displayedStatus: intl.t('request.status.approved'),
        totalTransfersAmount: 1000,
        totalTransfersAmountCurrency: 'EUR',
      },
      {
        id: '3',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-10'),
        },
        requestType: REQUEST_TYPES.TRANSFER,
        note: intl.t('transfers.empty-state.a3.request-transfer.dummy-data.row-3.spending-reason'),
        status: STATUS_REQUESTS.DECLINED,
        once: false,
        scheduledDate: addDaysToToday(6),
        displayedStatus: intl.t('transfers.status.declined'),
        amount: 450,
        amountCurrency: 'EUR',
      },
      {
        id: '4',
        beneficiary: {
          name: intl.t('empty-states.system.dummy-data.name-3'),
        },
        weekly: true,
        requestType: REQUEST_TYPES.TRANSFER,
        note: intl.t('transfers.empty-state.a3.request-transfer.dummy-data.row-4.spending-reason'),
        status: STATUS_REQUESTS.CANCELED,
        once: false,
        scheduledDate: addDaysToToday(7),
        displayedStatus: intl.t('request.status.canceled'),
        amount: 100,
        amountCurrency: 'EUR',
      },
    ],
  };

  let dummyData = isApprover ? createTransfers : requestTransfers;
  return dummyData;
};
