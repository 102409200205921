export const STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled',
  FINISHED: 'finished',
};

export const PROMO_BOX_LOCAL_STORAGE_KEY = 'RECURRING_INVOICES_SDD_ACTIVATION_PROMO_OPENED';
export const LOST_ELIGIBILITY_BANNER_LOCAL_STORAGE_KEY =
  'RECURRING_INVOICES_SDD_ACTIVATION_LOST_ELIGIBILITY_BANNER_OPENED';

export const RECURRENCE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
};

export const INVOICE_SUBSCRIPTION_STATUS_COLORS = {
  scheduled: 'purple',
  active: 'green',
  finished: 'green',
  canceled: 'red',
  suspended: 'gray',
};

export const TAB = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
};

export const VALID_EMAIL_REGEXP =
  /^[\x21-\x27\x2A-\x39\x41-\x7E]+@[\x21-\x27\x2A-\x39\x41-\x7E]+\.[\x21-\x27\x2A-\x39\x41-\x7E]+$/;
