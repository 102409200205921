export const EXERCISE_KINDS = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  GLOBAL: 'global',
};

export const PERIOD_TYPES = {
  CURRENT_PERIOD: 'current-period',
  NEXT_PERIOD: 'next-period',
};

export const METER_AMOUNTS_THRESHOLDS = {
  LOW: 80,
  HIGH: 100,
};

export const NO_PERIOD_ID = 'no-period-id';

// The amount of names to display in the "managed by" section in the budget card
export const NUM_OF_NAMES_TO_DISPLAY = 2;

export const BUDGET_ORIGIN = {
  list: 'list',
  show: 'show',
};

export const DEFAULT_SORT_BY = 'emitted_at:desc';
