export const CONNECT_FEATURE_LEVELS = {
  bronze: 'connect_integration_level_bronze',
  silver: 'connect_integration_level_silver',
  gold: 'connect_integration_level_gold',
};

export const UNDISPLAYED_INTEGRATION = 'undisplayed';

export const UNSORTED_INTEGRATION = 'unsorted';

export const INTEGRATION_TYPES = {
  internal: 'internal_integration',
  partnerships: 'partnerships',
  thirdParty: 'third_party_integration',
};

export const CATEGORIES = {
  ACCOUNTING: 'accounting',
  BANKS: 'banks',
  BUSINESS_PAYMENTS: 'business-payments',
  FINANCE: 'finance',
  HR: 'hr',
  CLOUD_STORAGE: 'cloud-storage',
  PRODUCTIVITY: 'productivity',
};

export const STAKEHOLDERS = {
  EXTERNAL_ACCOUNTANTS: 'external-accountants',
  FINANCING_TEAMS: 'financing-teams',
  FREELANCERS: 'freelancers',
};

export const COLLECTION_TYPES = {
  INTEGRATIONS: 'integrations',
  PARTNERSHIPS: 'partnerships',
  EBICS: 'ebics',
};

export const COLLECTIONS = [COLLECTION_TYPES.INTEGRATIONS, COLLECTION_TYPES.PARTNERSHIPS];

export const COLLECTIONS_EBICS_ACCESS = [...COLLECTIONS, COLLECTION_TYPES.EBICS];

export const CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS = 14;

export const CONNECTION_EXPIRATION_STATUS = {
  ACTIVE: 'active',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  UNKNOWN: 'unknown',
};

export const BANK_CONNECTION_STATUS = {
  SYNC_IN_PROGRESS: 'SYNC_IN_PROGRESS',
  SYNCHRONIZED: 'SYNCHRONIZED',
  SUSPENDED: 'SUSPENDED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  ACTION_REQUIRED_ON_BANK: 'ACTION_REQUIRED_ON_BANK',
  PROVIDER_ISSUE: 'PROVIDER_ISSUE',
};

export const BANK_CONNECTION_ACTIONABLE_STATUSES = [
  BANK_CONNECTION_STATUS.SUSPENDED,
  BANK_CONNECTION_STATUS.PASSWORD_EXPIRED,
  BANK_CONNECTION_STATUS.PASSWORD_UPDATED,
  BANK_CONNECTION_STATUS.ACTION_REQUIRED_ON_BANK,
];

export const BANK_CONNECTION_FAILURE_STATUSES = [
  ...BANK_CONNECTION_ACTIONABLE_STATUSES,
  BANK_CONNECTION_STATUS.PROVIDER_ISSUE,
];

export const BANK_CONNECTION_PROVIDER = {
  POWENS: 'POWENS',
  FINAPI: 'FINAPI',
};

export const BANK_CONNECTION_OUTCOME = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const BANK_CONNECTIONS_PAGE_SIZE = 999;
