export const USER_ACTIONS_STATUS = {
  ENABLED: 'enabled',
  DISMISSED: 'dismissed',
};

export const USER_ACTIONS_TYPE = {
  SETUP: 'setup',
  REQUIRED: 'required',
  DISCOVERY: 'discovery',
};

export const USER_ACTIONS_CTA_TYPE = {
  LINK: 'LINK',
  LINK_TO: 'LINK_TO',
  LINK_TO_FLOW: 'LINK_TO_FLOW',
  BUTTON: 'BUTTON',
};

export const USER_ACTIONS_ILLUSTRATION_TYPE = {
  SVG: 'SVG',
  LOTTIE: 'LOTTIE',
};
