import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const name = 'client-hub';
const featureName = 'receivableInvoices';

export const getEmptyStateConfig = (intl, { ctaCallback }) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    permissionKey: 'receivableInvoices',
    variations: {
      ES_A3: [
        {
          output: {
            code: CODES.ES_A3,
            layout: LAYOUT.DISCOVER_PREVIEW,
            dummyData,
            badgeType: BADGE_TYPE.ACTIVATE,
            badgeText: intl.t('empty-states.system.activate.a3.badge'),
            title: intl.t('client-list.list.empty-state.a3.title'),
            subtitle: intl.t('client-list.list.empty-state.a3.subtitle'),
            ctaText: intl.t('client-list.list.empty-state.a3.cta'),
            onClick: ctaCallback,
            tracking: getTrackingNameAndProperties({ name: 'add-client', type: TYPES.ACTIVATE }),
          },
        },
      ],
    },
  };
};

export const getDummyData = intl => {
  return {
    columnsToShow: {
      name: true,
      email: true,
    },
    items: [
      {
        name: intl.t('empty-states.system.dummy-data.name-5'),
        email: intl.t('empty-states.system.dummy-data.email-1'),
      },
      {
        name: intl.t('empty-states.system.dummy-data.name-10'),
        email: intl.t('empty-states.system.dummy-data.email-2'),
      },
      {
        name: intl.t('empty-states.system.dummy-data.name-1'),
        email: intl.t('empty-states.system.dummy-data.email-3'),
      },
      {
        name: intl.t('empty-states.system.dummy-data.name-2'),
        email: intl.t('empty-states.system.dummy-data.email-4'),
      },
    ],
  };
};
