export const PAY_LATER_ELIGIBILITY_STATUSES = {
  ELIGIBLE: 'eligible',
  NON_ELIGIBLE: 'non-eligible',
  NO_MORE_ELIGIBLE: 'no-more-eligible',
};

export const PAY_LATER_SIGNATURE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const REPAYMENT_STATUS = {
  AT_RISK: 'at_risk',
  LATE: 'late',
  FROZEN: 'frozen',
};

export const CONTRACT_STATUS = {
  NOT_SIGNED: 'not_signed',
  SIGNED: 'signed',
  ON_HOLD: 'on_hold',
};

export const FAQ_TRACKING_ORIGIN = {
  ELIGIBLE: 'cockpit_eligible',
  ON_HOLD: 'cockpit_onhold',
  NO_MORE_ELIGIBLE: 'cockpit_no_more_eligible',
  DEDICATED_FLOW: 'dedicated_flow',
};

export const PAY_LATER_MINIMUM_TRANSFER_AMOUNT = 150;

export const PAY_LATER_SIGNATURE_EVENT = {
  ORIGIN: 'https://yousign.app',
  TYPE: 'yousign',
};

export const FINANCING_INSTALLMENT_STATUS = {
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing',
  FROZEN: 'frozen',
  NOT_PAID: 'not_paid',
  RECOVERED: 'recovered',
  MONEY_LOSS: 'money_loss',
  CANCELLED: 'cancelled',
  PAID: 'paid',
};

export const COMPLETED_INSTALLMENT_STATUSES = [
  FINANCING_INSTALLMENT_STATUS.RECOVERED,
  FINANCING_INSTALLMENT_STATUS.MONEY_LOSS,
  FINANCING_INSTALLMENT_STATUS.CANCELLED,
  FINANCING_INSTALLMENT_STATUS.PAID,
];

export const IN_PROGRESS_INSTALLMENT_STATUSES = [
  FINANCING_INSTALLMENT_STATUS.SCHEDULED,
  FINANCING_INSTALLMENT_STATUS.PROCESSING,
  FINANCING_INSTALLMENT_STATUS.FROZEN,
  FINANCING_INSTALLMENT_STATUS.NOT_PAID,
];

export const PAY_LATER_THRESHOLD = {
  MAIN_ACCOUNT_LOW_BALANCE: 2000,
  MINIMUM_CREDIT_AMOUNT_VALUE: 150,
};

export const PAY_LATER_APPLICATION_ORIGIN = {
  NAVIGATION: 'navigation',
  COCKPIT: 'cockpit',
  TRANSFER: 'transfer',
};

export const INVOICE_ATTACHMENT_LIMITS = {
  issue_date_days_past: 60,
  due_date_days_limit: 60,
};

export const DEFAULT_ELIGIBILITY_RESPONSE = {
  available_credit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  credit_limit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  min_transfer_amount: {
    value: '150.00',
    currency: 'EUR',
  },
  contract_status: CONTRACT_STATUS.SIGNED,
  eligibility: PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
  pay_later_transfers_count: 0,
  invoice_attachment_limits: INVOICE_ATTACHMENT_LIMITS,
};

export const DEFAULT_INSTALLMENTS_RESPONSE = {
  total_amount: {
    value: '155.34',
    currency: 'EUR',
  },
  total_fee: {
    value: '5.34',
    currency: 'EUR',
  },
  instalments: [
    {
      date: '2023-05-23T00:00:00.000Z',
      amount: {
        value: '51.78',
        currency: 'EUR',
      },
    },
    {
      date: '2023-06-23T00:00:00.000Z',
      amount: {
        value: '51.78',
        currency: 'EUR',
      },
    },
    {
      date: '2023-07-23T00:00:00.000Z',
      amount: {
        value: '51.78',
        currency: 'EUR',
      },
    },
  ],
  remaining_credit_after_transfer: {
    currency: 'EUR',
    value: '4850.00',
  },
  available_credit_amount: {
    currency: 'EUR',
    value: '5000.00',
  },
};

export const DEFAULT_INSTALLMENTS_RESPONSE_V3 = {
  available_credit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  remaining_credit_after_transfer: {
    value: '5000.00',
    currency: 'EUR',
  },
  options: [
    {
      number_of_installments: 3,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '115.98',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '1705.33',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
    {
      number_of_installments: 6,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '204.13',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '867.36',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
    {
      number_of_installments: 9,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '293.26',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '588.14',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
  ],
};

export const PAY_LATER_VALIDATION_ERROR_TYPES = {
  INVALID_DATE: 'invalid_field_errors',
  SELF_TRANSFER: 'transfer',
  MISSING_DETAILS: 'missing_field_errors',
  ATTACHMENT_ID: 'attachment_id',
};

export const PAY_LATER_INVALID_DATE_ERRORS = {
  DUE_DATE: 'invoice_due_date_outside_valid_timespan',
  ISSUE_DATE: 'invoice_issue_date_too_old',
};

export const PAY_LATER_MISSING_DETAILS_ERRORS = {
  INVOICE_AMOUNT: 'missing_invoice_amount',
  ISSUE_DATE: 'missing_issue_date',
  SUPPLIER_IDENTIFIER: 'missing_supplier_identifier',
  SUPPLIER_NAME: 'missing_supplier_name',
};

export const PAY_LATER_NOT_MAIN_ACCOUNT_ERROR = 'main_account_not_selected';
export const LOCAL_STORAGE_PAY_LATER_MAIN_ACCOUNT_NOT_SELECTED_KEY =
  'pay-later-main-account-not-selected';

export const PAY_LATER_SELF_TRANSFER_ERROR = 'self_transfer';

export const PAY_LATER_AMOUNT_ERRORS = {
  LESS_THAN_THRESHOLD: 'amount_less_than_threshold',
  MORE_THAN_AVAILABLE_CREDIT: 'insufficient_available_credit',
};

export const PAY_LATER_SCHEDULING_RECURRENCE_ENABLED_ERROR =
  'scheduled_or_recurring_transfer_not_allowed';

export const PAY_LATER_PREVENT_FF_ERROR = 'prevent_pay_later_transfer_feature_flag_enabled';

export const PAY_LATER_ERRORS_PRIORITY = [
  PAY_LATER_PREVENT_FF_ERROR,
  PAY_LATER_AMOUNT_ERRORS.LESS_THAN_THRESHOLD,
  PAY_LATER_AMOUNT_ERRORS.MORE_THAN_AVAILABLE_CREDIT,
  PAY_LATER_INVALID_DATE_ERRORS.ISSUE_DATE,
  PAY_LATER_INVALID_DATE_ERRORS.DUE_DATE,
  PAY_LATER_MISSING_DETAILS_ERRORS.SUPPLIER_NAME,
  PAY_LATER_MISSING_DETAILS_ERRORS.INVOICE_AMOUNT,
  PAY_LATER_MISSING_DETAILS_ERRORS.ISSUE_DATE,
  PAY_LATER_MISSING_DETAILS_ERRORS.SUPPLIER_IDENTIFIER,
  PAY_LATER_SELF_TRANSFER_ERROR,
  PAY_LATER_NOT_MAIN_ACCOUNT_ERROR,
  PAY_LATER_SCHEDULING_RECURRENCE_ENABLED_ERROR,
];

export const MIN_TRANSFER_AMOUNT = {
  value: '150.00',
  currency: 'EUR',
};

export const PAY_LATER_FLOW_ORIGIN = {
  COCKPIT: 'cockpit',
  DEEPLINK: 'deeplink',
  GET_STARTED_CARD: 'get_started_card',
  SUCCESS_SCREEN: 'success_screen',
  TRANSFER_FLOW: 'transfer_flow',
  PROMOTIONAL_CARD: 'promotional_card',
};

export const PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY = 'pay-later-promotional-card';
