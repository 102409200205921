import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { addDaysToToday } from 'qonto/utils/date';

const name = 'requests';

export const TABS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
};

const NO_TASKS_LOTTIE = '/lotties/empty-state/no-tasks.json';

export const getEmptyStateConfig = (intl, featureName = 'connectIntegrationLevelSilver') => {
  return {
    name,
    featureName,
    isNewLogic: true,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              // requests_v3 price plan feature and at least one of the permissions: request_cards.create, request_expense_reports.create or request_mileages.create
              activateAbilities: [`canCreateCardOrExpenseReportOrMileageRequests`],
            },
            output: {
              dummyData: requesterDummyData({ intl, isAllRequests: true }),
              title: intl.t('requests.empty-state.a3.all-requests.title'),
              subtitle: intl.t('requests.empty-state.a3.all-requests.subtitle'),
            },
          },
          {
            inputs: {
              // requests_v3 price plan feature and request_transfers.create permission
              activateAbilities: [`canCreateTransferRequests`],
            },
            output: {
              dummyData: requesterDummyData({ intl, isAllRequests: false }),
              title: intl.t('requests.empty-state.a3.transfer-requests.title'),
              subtitle: intl.t('requests.empty-state.a3.transfer-requests.subtitle'),
            },
          },
          // Tasks I3 Variations
          {
            inputs: {
              // requests_v3 price plan feature and at least one of the permissions: request_transfers.review, request_cards.review, request_expense_reports.review or request_mileages.review
              copyAbilities: [`canReviewExpenseReportMileageTransferCardRequests`],
            },
            output: {
              code: CODES.ES_I3,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              dummyData: approverDummyData({ intl, isAllTasks: true }),
              title: intl.t('tasks.empty-state.i3.title'),
              subtitle: intl.t('tasks.empty-state.i3.all-tasks.subtitle'),
            },
          },
          {
            inputs: {
              // request_transfers.review permission (canReadEbics)
              copyAbilities: [`canReviewTransfers`],
            },
            output: {
              code: CODES.ES_I3,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              dummyData: approverDummyData({ intl, isAllTasks: false }),
              title: intl.t('tasks.empty-state.i3.title'),
              subtitle: intl.t('tasks.empty-state.i3.transfer-tasks.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        lottieSrc: NO_TASKS_LOTTIE,
        variations: [
          {
            inputs: {
              customInputs: {
                tab: TABS.PENDING,
              },
            },
            output: {
              title: intl.t('tasks.empty-state.i1.to-approve.title'),
              subtitle: intl.t('tasks.empty-state.i1.to-approve.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
            },
            output: {
              title: intl.t('tasks.empty-state.i1.completed.title'),
              subtitle: intl.t('tasks.empty-state.i1.completed.subtitle'),
            },
          },
        ],
      },

      ES_I3: {
        code: CODES.ES_I3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              // requests_v3 price plan feature and at least one of the permissions: request_transfers.review, request_cards.review, request_expense_reports.review or request_mileages.review
              copyAbilities: [`canReviewExpenseReportMileageTransferCardRequests`],
            },
            output: {
              dummyData: approverDummyData({ intl, isAllTasks: true }),
              title: intl.t('tasks.empty-state.i3.title'),
              subtitle: intl.t('tasks.empty-state.i3.all-tasks.subtitle'),
            },
          },
          {
            inputs: {
              // request_transfers.review permission (canReadEbics)
              copyAbilities: [`canReviewTransfers`],
            },
            output: {
              dummyData: approverDummyData({ intl, isAllTasks: false }),
              title: intl.t('tasks.empty-state.i3.title'),
              subtitle: intl.t('tasks.empty-state.i3.transfer-tasks.subtitle'),
            },
          },
        ],
      },
    },
  };
};

// Requests
export const requesterDummyData = ({ intl, isAllRequests }) => {
  if (isAllRequests) {
    return {
      type: 'requester',
      items: [
        {
          requestType: REQUEST_TYPES.EXPENSE_REPORT,
          note: intl.t('requests.empty-state.a3.all-requests.dummy-data.row-1.spending-reason'),
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          amount: { value: 25, currency: 'EUR' },
        },
        {
          requestType: REQUEST_TYPES.FLASH_CARD,
          preExpiresAt: addDaysToToday(365),
          note: intl.t('requests.empty-state.a3.all-requests.dummy-data.row-2.spending-reason'),
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          paymentLifespanLimit: 150,
          currency: 'EUR',
        },
        {
          requestType: REQUEST_TYPES.TRANSFER,
          once: true,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-6'),
          note: intl.t('requests.empty-state.a3.all-requests.dummy-data.row-3.spending-reason'),
          status: STATUS.APPROVED,
          displayedStatus: intl.t('request.status.approved'),
          amount: 115,
          amountCurrency: 'EUR',
        },
        {
          requestType: REQUEST_TYPES.VIRTUAL_CARD,
          note: intl.t('requests.empty-state.a3.all-requests.dummy-data.row-4.spending-reason'),
          status: STATUS.APPROVED,
          displayedStatus: intl.t('request.status.approved'),
          paymentMonthlyLimit: 500,
          currency: 'EUR',
        },
      ],
    };
  } else {
    return {
      type: 'requester',
      items: [
        {
          requestType: REQUEST_TYPES.TRANSFER,
          once: true,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-2'),
          note: intl.t(
            'requests.empty-state.a3.transfer-requests.dummy-data.row-1.spending-reason'
          ),
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          amount: 25,
          amountCurrency: 'EUR',
        },
        {
          requestType: REQUEST_TYPES.MULTI_TRANSFER,
          once: true,
          totalTransfersCount: 10,
          note: intl.t(
            'requests.empty-state.a3.transfer-requests.dummy-data.row-2.spending-reason'
          ),
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          totalTransfersAmount: 150,
          totalTransfersAmountCurrency: 'EUR',
        },
        {
          requestType: REQUEST_TYPES.TRANSFER,
          once: true,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-6'),
          note: intl.t(
            'requests.empty-state.a3.transfer-requests.dummy-data.row-3.spending-reason'
          ),
          status: STATUS.APPROVED,
          displayedStatus: intl.t('request.status.approved'),
          amount: 115,
          amountCurrency: 'EUR',
        },
        {
          requestType: REQUEST_TYPES.TRANSFER,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-1'),
          scheduledDate: addDaysToToday(7),
          note: intl.t(
            'requests.empty-state.a3.transfer-requests.dummy-data.row-4.spending-reason'
          ),
          status: STATUS.APPROVED,
          displayedStatus: intl.t('request.status.approved'),
          amount: 500,
          amountCurrency: 'EUR',
        },
      ],
    };
  }
};

// Tasks
export const approverDummyData = ({ intl, isAllTasks }) => {
  let date1 = addDaysToToday(1);
  let date2 = addDaysToToday(365);

  let formattedDate1 = dateToken({
    date: date1,
    locale: intl.locale,
    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });

  let formattedDate2 = dateToken({
    date: date2,
    locale: intl.locale,
    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });

  if (isAllTasks) {
    return {
      type: 'pending',
      items: [
        {
          requestType: REQUEST_TYPES.EXPENSE_REPORT,
          note: intl.t('tasks.empty-state.i3.all-tasks.dummy-data.row-1.notes'),
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-1'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-2'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 285, currency: 'EUR' },
          attachmentIds: [''],
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.all-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.FLASH_CARD,
          note: `${intl.t('request.table.cell.usable-until')} ${formattedDate1}`,
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-2'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-3'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 480, currency: 'EUR' },
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.all-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.VIRTUAL_CARD,
          note: `${intl.t('request.table.cell.usable-until')} ${formattedDate2}`,
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-2'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-3'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 480, currency: 'EUR' },
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.all-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.TRANSFER,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-5'),
          reference: intl.t('tasks.empty-state.i3.all-tasks.dummy-data.row-4.notes'),
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-3'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-4'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 1620, currency: 'EUR' },
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.all-tasks.account-name') },
        },
      ],
    };
  } else {
    return {
      type: 'pending',
      items: [
        {
          requestType: REQUEST_TYPES.TRANSFER,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-5'),
          reference: intl.t('tasks.empty-state.i3.transfer-tasks.dummy-data.row-1.notes'),
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-3'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-4'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 1620, currency: 'EUR' },
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.transfer-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.MULTI_TRANSFER,
          note: intl.t('tasks.empty-state.i3.transfer-tasks.dummy-data.row-2-4.notes'),
          totalTransfersCount: 10,
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-2'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-1'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 2385, currency: 'EUR' },
          attachmentIds: [''],
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.transfer-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.TRANSFER,
          beneficiaryName: intl.t('empty-states.system.dummy-data.name-6'),
          reference: intl.t('tasks.empty-state.i3.transfer-tasks.dummy-data.row-3.notes'),
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-4'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-5'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 1620, currency: 'EUR' },
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.transfer-tasks.account-name') },
        },
        {
          requestType: REQUEST_TYPES.MULTI_TRANSFER,
          note: intl.t('tasks.empty-state.i3.transfer-tasks.dummy-data.row-2-4.notes'),
          totalTransfersCount: 10,
          initiator: {
            id: 'klod',
            fullName: intl.t('empty-states.system.dummy-data.name-2'),
            team: {
              name: intl.t('empty-states.system.dummy-data.team-1'),
            },
          },
          createdAt: addDaysToToday(0),
          requestAmount: { value: 2385, currency: 'EUR' },
          attachmentIds: [''],
          bankAccount: { safeName: intl.t('tasks.empty-state.i3.transfer-tasks.account-name') },
        },
      ],
    };
  }
};
