export const REFERRAL_STATUSES = {
  EARNED: 'rewarded',
  PENDING: 'registered',
};

export const REWARDS_STATUS = {
  PENDING: 'pending',
  EARNED: 'earned',
  EXPIRED: 'expired',
};
