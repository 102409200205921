const COMPONENT_NAMESPACE = 'transfers/international-out/dynamic-form';

const COUNTRY_TRIGGER_SELECT_COMPONENT = 'power-select/country-select/trigger';
const DEFAULT_TRIGGER_SELECT_COMPONENT = 'power-select-customs/trigger';

const ALLOWED_FIELD_TYPES = Object.freeze({
  DATE: 'date',
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'text',
});

const VALIDATION_TRIGGER_TYPES = Object.freeze({
  BLUR: 'blur',
  INPUT: 'input',
});

export {
  ALLOWED_FIELD_TYPES,
  COMPONENT_NAMESPACE,
  COUNTRY_TRIGGER_SELECT_COMPONENT,
  DEFAULT_TRIGGER_SELECT_COMPONENT,
  VALIDATION_TRIGGER_TYPES,
};
